import React from 'react'
import { graphql } from 'gatsby'
import SliceZone from '../components/slice-zone'
import Layout from '../components/layout'
import {Helmet} from "react-helmet";

const Class = ({ data }) => {
  if (!data) return null

  const page = data.prismicClass.data

  return (
      <Layout>
        <Helmet>
            <title>{page.title.text}</title>
            <meta name="description" content={page.description.text} />
          </Helmet>
        <SliceZone slices={page.body} />
      </Layout>
  )
}

export const query = graphql`
query ClassQuery($uid: String) {
    prismicClass(uid: {eq: $uid}) {
      uid
      type
      id
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ... on PrismicClassBodyClassHero {
            id
            slice_label
            slice_type
            primary {
              title {
                text
              }
              description {
                text
              }
              enroll_button_text {
                text
              }
              enroll_button_link {
                raw
                uid
                type
                url
              }
              more_info_button_text {
                text
              }
              image {
                url
                alt
              }
              featured_1_icon {
                url
                alt
              }
              featured_1_label {
                text
              }
              featured_1_text {
                text
              }
              featured_1_description {
                text
              }
              featured_2_icon {
                url
                alt
              }
              featured_2_label {
                text
              }
              featured_2_text {
                text
              }
              featured_2_description {
                text
              }
              featured_3_icon {
                url
                alt
              }
              featured_3_label {
                text
              }
              featured_3_text {
                text
              }
              featured_3_description {
                text
              }
              featured_4_icon {
                url
                alt
              }
              featured_4_label {
                text
              }
              featured_4_text {
                text
              }
              featured_4_description {
                text
              }
            }
          }
          ... on PrismicClassBodyClassInformation {
            id
            slice_label
            slice_type
            primary {
              overview {
                text
              }
              review_image {
                url
                alt
              }
              review_text {
                text
              }
              review_name {
                text
              }
              contact_icon {
                url
                alt
              }
              contact_title {
                text
              }
              contact_text {
                text
              }
              contact_button_text {
                text
              }
            }
            items {
              goal_icon {
                url
                alt
              }
              goal {
                text
              }
            }
          }
          ... on PrismicClassBodyClassImages {
            id
            slice_label
            slice_type
            primary {
              image_1 {
                url
                alt
              }
              image_2 {
                url
                alt
              }
              image_3 {
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`

export default Class
